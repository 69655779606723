import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Layout, Menu, Dropdown } from 'antd';
import { DownOutlined,PicCenterOutlined } from '@ant-design/icons';
import { Cookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../Commons/Context';
import { useDispatch } from 'react-redux';
import { addSubscriptions } from '../redux/slices/subscriptions';
const cookie = new Cookies();
const { Header } = Layout;

const DashboardHeader = (props) => {
	const { CustomIcon, CustomHeader, CustomLink } = StyledComponent;
	const { siderHidden, setSiderHidden } = props;
	const { currentUser } = useContext(AppContext);
	const dispatch = useDispatch()
	const history = useHistory();

	const menu = (
		<Menu>
			<Menu.Item key={"profile"}>
				<Link to='/system/profile'>Profile</Link>
			</Menu.Item>
			<Menu.Item key={"logout"}>
				<Link to="#" onClick={() => handleLogout()}>Logout</Link>
			</Menu.Item>
		</Menu>
	);

	const handleLogout = () => {
		
		cookie.remove('Authorization', { path: '/' });
		history.replace('/login');
		dispatch(addSubscriptions({}))
		localStorage.clear()

	};

	return (
		<CustomHeader mode='horizontal'>
			{/* <CustomIcon
				
			> */}
				<CustomIcon onClick={() => setSiderHidden(!siderHidden)} />
			{/* </CustomIcon> */}

			<Dropdown size='large' overlay={menu}>
				<CustomLink className='ant-dropdown-link' siderHidden={siderHidden}>
					{`${currentUser && currentUser?.toUpperCase()}  `}
					<DownOutlined />
				</CustomLink>
			</Dropdown>
		</CustomHeader>
	);
};

const StyledComponent = {
	CustomHeader: styled(Header)`
		width: 100%;
		z-index: 1;
		background-color: white !important;
		padding: 0px !important;
		position: fixed;
		width: 100%;
	`,
	CustomIcon: styled(PicCenterOutlined)`
		font-size: 1.5rem;
		padding: 0.8rem;
		marginrigh: 20rem;
		cursor: pointer;
		float: left;
	`,
	CustomLink: styled.a`
		float: right;
		margin-right: ${({ siderHidden }) => (siderHidden ? '115px' : '225px')};
	`,
};

DashboardHeader.propTypes = {
	sideBarHidden: PropTypes.bool,
	setSideBarHidden: PropTypes.func,
};

export default DashboardHeader;
