import { api, FormatResponse } from '../index';
const USER_DETAILS_URL = '/api/v1/users/details';
const ALL_USERS_URL = '/api/v1/users/all-users';
const ALL_USERS_BY_ADMIN_URL = '/api/v1/users/get-all-users-by-admin';
const DELETE_USER_BY_ADMIN_URL = '/api/v1/users/delete-user-by-admin'
const UPDATE_URL = '/api/v1/users/edit-profile'

export const getUserProfile = async () => {
    const mappingRes = await api.get(USER_DETAILS_URL);
    return new FormatResponse(mappingRes);
};

export const fetchAllUsers = async (params) => {
    const res = await api.get(ALL_USERS_URL,{params});
    return new FormatResponse(res);
};
export const fetchAllUsersByAdmin = async () => {
    const res = await api.get(ALL_USERS_BY_ADMIN_URL);
    return new FormatResponse(res);
};
export const deleteAUserByAdmin = async (id) => {
    const res = await api.delete(`/api/v1/users/delete-user-by-admin/${id}`);
    return new FormatResponse(res);
};


export const updateUserProfile = async(data) => {
    const response = await api.put(UPDATE_URL, data)
    if(response && response.status){
        return new FormatResponse(response)
    }
}