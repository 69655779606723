import React, { useState, useEffect } from 'react';
import DashboardSidebar from '../../Components/SideBar';
import DashboardHeader from '../../Components/Header';
import { Route } from 'react-router-dom';
import styled from 'styled-components';
import { Row, Col, Layout } from 'antd';
import { userRoutes } from '../../Routes/user';
import { useCookies } from 'react-cookie';
import '../../Commons/style/style.scss';
import { AppContext } from '../../Commons/Context';
import jwt_decode from 'jwt-decode';
import './style.scss';
import { useSelector } from 'react-redux';
import { getSubscription } from '../../redux/slices/subscriptions';
import { adminRoutes } from '../AdminConsole/adminRoutes';
import { Header } from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';
import CustomHeader from '../../Components/CustomeHeader';
const { Content } = Layout;

const Divider = styled.hr`
	border: 0.5px solid #939598;
	margin: 0.2rem 0.5rem 0.5rem 0.5rem;
`;

const NavigationRoutes = (routes) => {
	let nr = [];
	routes.forEach((val, key) => {
		if (val.items && val.items.length > 0) {
			val.items.map((v, k) => {
				nr.push(
					<Route exact path={v.path} component={v.component} key={val.pk + k} />
				);
			});
		} else {
			nr.push(
				<Route exact path={val.path} component={val.component} key={key} />
			);
		}
	});

	return nr;
};

const MainContainer = (props) => {
	const [siderHidden, setSiderHidden] = useState(false);
	const [cookies] = useCookies(['Authorization']);
	const [currentUser, setCurrentUser] = useState();

	useEffect(() => {
		if (!cookies.Authorization) {
			props.history.replace('/login');
		} else {
			const subs = JSON.parse(localStorage.getItem("subscription"))
			if (subs === null || subs.paymentgatewayref === undefined) {
				props.history.replace('/payment')
			}
			setCurrentUser(jwt_decode(cookies.Authorization).firstName);
		}
	}, []);

	const routeFilter = (routes)=>{
		const token = localStorage.getItem('token');
		const {userType} = jwt_decode(token);
		if(userType === 'ADM'){
		   return [...adminRoutes]
		}
		return routes;
	}
	return (
		<AppContext.Provider value={{ currentUser }}>
			<Layout >
				<CustomHeader
					siderHidden={siderHidden}
					setSiderHidden={(val) => setSiderHidden(val)}
				/>
				<Layout >
					<DashboardSidebar
						// className="common-style"
						siderHidden={siderHidden}
						setSiderHidden={(val) => setSiderHidden(val)}
						routes={routeFilter(userRoutes)}
					/>
					<Layout
						style={{
							paddingLeft:"20px",
							paddingRight:"20px",
							paddingTop:"20px",
						}}
						className='main-layout-style'
					>
						<Content style={{}}>
							<Row
								style={{
									paddingTop:"0px",
									// paddingBottom:"70px",
								}}
								justify='start'
							 >
								<Col xs={24}>
									<Row>{NavigationRoutes(routeFilter(userRoutes))}</Row>
								</Col>
							</Row>
						</Content>

					</Layout>
				</Layout>
            </Layout>
		</AppContext.Provider>
	);

	// return (
	// 	<AppContext.Provider value={{ currentUser }}>
	// 		<Layout className='main-layout-style'>
	// 			{/* <Header style={{backgroundColor:"transparent"}} className="header">

	// 			</Header> */}
	// 			<CustomHeader
	// 				siderHidden={siderHidden}
	// 				setSiderHidden={(val) => setSiderHidden(val)}
	// 			/>
	// 			<Layout className="child-layout-style common-style" style={{ padding: '24px',margin: '20px'}}>
	// 					{/* <Sider className="site-layout-background" width={200}>

	// 					</Sider> */}
						// <DashboardSidebar
						// 	className="common-style"
						// 	siderHidden={siderHidden}
						// 	setSiderHidden={(val) => setSiderHidden(val)}
						// 	routes={routeFilter(userRoutes)}
						// />
						// <Content>
						// 	<Row justify='start'>
						// 		<Col xs={24}>
						// 			<Row>{NavigationRoutes(routeFilter(userRoutes))}</Row>
						// 		</Col>
						// 	</Row>
						// </Content>
	// 				</Layout>
	// 		</Layout>
	// 	</AppContext.Provider>
	// );
	// return (
	// 	<AppContext.Provider value={{ currentUser }}>
	// 		<Layout style={{ height: '100vh', userSelect: 'none' }}>
	// 			<DashboardSidebar
	// 				siderHidden={siderHidden}
	// 				setSiderHidden={(val) => setSiderHidden(val)}
	// 				routes={routeFilter(userRoutes)}
	// 			/>
	// 			<Layout>
	// 				<DashboardHeader
	// 					siderHidden={siderHidden}
	// 					setSiderHidden={(val) => setSiderHidden(val)}
	// 				/>
	// 				<Divider />
	// 				<Content style={{ marginTop: '1.8rem' }}>
	// 					<Row justify='start'>
	// 						<Col xs={24}>
	// 							<Row>{NavigationRoutes(routeFilter(userRoutes))}</Row>
	// 						</Col>
	// 					</Row>
	// 				</Content>
	// 			</Layout>
	// 		</Layout>
	// 	</AppContext.Provider>
	// );
};

export default MainContainer;
