import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Form, Select } from 'antd';
import './style.scss';
import { WorkflowContext, StepContext } from '../../Commons/Context';
import { keys as keycreate, isObject } from 'lodash';
import { PrimaryButton } from '../../Commons';
import { api } from '../../Http';
import { requiredRule, flatten } from '../../Commons/utils';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const QuoteUpdateForm = () => {
    const { sampleData, sampleData2, setActionData, SObject, mappings, additionalQuery, workflowId, setMappings, form } = useContext(WorkflowContext);
    const { id, next } = useContext(StepContext);

    console.log({ workflowId })

    const [keys, setKeys] = useState([]);
    const [returnMap, setReturnMap] = useState(false);
    const history = useHistory();
    const step2 = useSelector(state => state.latestWorkflow.latestWorkflow.step2)
    console.log({ step2 })

    const taxTypes = [
        { label: 'GST on Income (OUTPUT) ', value: 'OUTPUT' },
        { label: 'GST on Expenses (INPUT)', value: 'INPUT' },
        { label: 'GST Free Expenses (EXEMPTEXPENSES)', value: 'EXEMPTEXPENSES' },
        { label: 'GST Free Income (EXEMPTOUTPUT)', value: 'EXEMPTOUTPUT' },
        { label: 'BAS Excluded (BASEXCLUDED)', value: 'BASEXCLUDED' },
        { label: 'GST on Imports (GSTONIMPORTS)', value: 'GSTONIMPORTS' },
    ];

    const boolOptions = [
        { label: 'True', value: true },
        { label: 'False', value: false },
    ];


    // useEffect(() => {
    //   setKeys(keycreate(sampleData));
    // }, []);


    useEffect(() => {
        setKeys(keycreate(sampleData));
        console.log({ mappings, sampleData });
        let tmp = {};
        console.log({mappings})
        mappings?.forEach((v) =>
            (v.type === 'TRIG')
                ?
                (
                    tmp[v.value] = [`${v.valueStepOrder}.${v.field}`],
                    tmp[v.label] = `${v.valueStepOrder}.${v.value}: ${sampleData[v.value]}`
                )
                :
                (v.type === 'ACT')
                    ?
                    (
                        tmp[v.field] = [`${v.valueStepOrder}.${v.value}`],
                        tmp[v.label] = `${v.valueStepOrder}.${v.field}: ${sampleData[v.value]}`
                    )
                    :
                    (tmp[v.field] = `${v.value}`)
        );
        if (tmp) {
            console.log({tmp})
            form.setFieldsValue(tmp);
        }
    }, []);

    const handleSubmit = () => {
        form
            .validateFields()
            .then((rawFormData) => {
                let ACTION_DATA = {}; //Data to be send as a product of the trigger
                let MAPPINGS = [];
                let snum;

                let flatFormData = flatten({ ...rawFormData });

                keycreate(flatFormData)
                    .filter((k) => flatFormData[k])
                    .map((key) => {
                        if (Array.isArray(rawFormData[key]) && rawFormData[key].length === 0) {
                            // skip
                        } else {
                            let val = flatFormData[key].split('.');
                            let kname = val.length > 1 ? val[1] : val[0];
                            snum = val.length > 1 ? val[0] : undefined;

                            let kval = snum && parseInt(snum) === 1 ? sampleData[kname]
                                : snum && parseInt(snum) === 2 ? sampleData2[kname]
                                    : kname;
                            console.log({ sampleData }, { sampleData2 })
                            ACTION_DATA = { ...ACTION_DATA, [key]: kval };
                            MAPPINGS = snum ?
                                (key === 'QuoteID')
                                    ? [...MAPPINGS, { type: 'ACT', field: kname, value: key, stepId: step2.id, valueStepOrder: snum }]
                                    : [...MAPPINGS, { type: 'TRIG', field: kname, value: key, stepId: step2.id, valueStepOrder: snum }]

                                :
                                (key === 'QuoteID')
                                    ? [...MAPPINGS, { type: 'ACT', field: kname, value: key, stepId: step2.id, valueStepOrder: snum }]
                                    : [...MAPPINGS, { type: 'CON', field: key, value: kname, stepId: step2.id }];
                        }
                        return key
                    });
                MAPPINGS = [...MAPPINGS, { type: 'ACT', field: sampleData2.field, value: sampleData2.searchValue, stepId: step2.id, valueStepOrder: snum }]
                console.log(ACTION_DATA, { MAPPINGS })
                setActionData(ACTION_DATA);
                setMappings(MAPPINGS);
                api.post('/api/v1/workflows/mappings', { mappings: MAPPINGS, sObject: SObject.name, workflowId: workflowId, additionalQuery: additionalQuery })
                history.push('/system/workflows');

            })
            .catch((e) => console.log(e));
    };

    const RoundedSelect = (props) => {
        const { label, placeholder, name, required, specialOptions, specialOptionsOnly, hint, onSelect } = props;

        return (
            <Col span={22}>
                <div className='label'>
                    {label} <span className={required ? 'fr' : 'hidden'}>(required)</span>
                </div>
                <Form.Item name={name} required={required} rules={required ? [requiredRule] : null}>
                    <Select className='rounded' mode={specialOptions ? null : 'tags'}
                        placeholder={placeholder} allowClear onSelect={onSelect}>

                        {specialOptions && specialOptions.map((v) => <Select.Option key={v.value} value={v.value}>{v.label}</Select.Option>)}

                        {!specialOptionsOnly &&
                            keys.map(
                                (v, k) =>
                                    !isObject(sampleData[v]) && (
                                        <Select.Option value={`1.${v}`} key={`1.${k}`}>
                                            <div className='opt-div'>
                                                <span className='key'>{`1.${v}:  `}</span>
                                                {`${sampleData[v]}`}
                                            </div>
                                        </Select.Option>
                                    )
                            )}
                    </Select>
                </Form.Item>
                {hint && <div className='hint'>{hint}</div>}
            </Col>
        );
    };

    return (
        <Row className='padded xinvform'>
            <div className='title'> {returnMap ? 'Return Mapping' : 'Create Quote Mapping'} </div>
            <Col span={24}>

                <Form layout='vertical'
                    form={form}
                    scrollToFirstError={true}
                    onFinish={handleSubmit}
                    onValuesChange={() => console.log({ formData: form.getFieldsValue() })}
                >
                    <Row hidden={returnMap} justify='center'>
                        {/* <RoundedSelect label='Amount Due' name='AmountDue' placeholder='Type or insert..' specialOptions={taxTypes} />
                        <RoundedSelect label='Amount Paid' name='AmountPaid' placeholder='Type or insert..' /> */}
                        <RoundedSelect label='Status' name='Status' placeholder='Choose one..' />
                        <RoundedSelect label='Expiry Date' name='ExpiryDate' placeholder='Type or insert....' />
                        <RoundedSelect label='Reference' name='Reference' placeholder='Type or insert....' />

                        <PrimaryButton className='btn-long' size='large' htmlType='submit'>
                            Finish
                        </PrimaryButton>
                    </Row>
                </Form>
            </Col>
        </Row>
    );
};

export default QuoteUpdateForm;